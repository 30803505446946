.container {
    position: relative;
}

.heading {
    color: var(--display-onlight-primary);
    position: relative;
}

.subheading {
    color: var(--display-onlight-secondary);
}

.spot {
    position: absolute;
    left: calc(var(--offset-x) * 1px);
    top: calc(var(--offset-y) * 1px);

    @media (--screen-lt-md) {
        block-size: 150px;
        inline-size: auto;
        left: calc(var(--offset-mobile-x) * 1px);
        top: calc(var(--offset-mobile-y) * 1px);
    }
}
