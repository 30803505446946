.modal {
    --is-positioned: ;
    position: relative;
    inset-inline-start: var(--is-positioned, var(--modal-x, 0));
    inset-block-start: var(--is-positioned, var(--modal-y, 0));

    display: flex;
    flex-direction: column;
    gap: var(--space-24);
    padding-inline: var(--space-16);
    padding-block-start: var(--space-12);
    padding-block-end: var(--space-16);
    inline-size: min-content;
    block-size: min-content;
    background: var(--elevated-background);
    border-radius: var(--border-radius-xl);
    border: 1px solid var(--elevated-border);
    z-index: var(--product-ui-z-index-popup);
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.absolutePositioned {
    --is-positioned: initial;
    position: absolute;
}
