.iconButton {
    --attribute-button-size: var(--tube-height-xs);
    --attribute-icon-size: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: var(--attribute-button-size);
    block-size: var(--attribute-button-size);
    overflow: hidden;
    border-radius: var(--border-radius-sm);

    svg {
        color: var(--display-onlight-tertiary);
    }

    svg {
        inline-size: var(--attribute-icon-size);
        block-size: var(--attribute-icon-size);
    }
}

.lg {
    --attribute-button-size: var(--tube-height-sm);
    --attribute-icon-size: 24px;
}

.hovered {
    background-color: var(--actionable-quaternary-background-onhover);

    svg {
        color: var(--display-onlight-primary);
    }
}

.sidebar {
    &.hovered {
        background-color: var(--actionable-quaternary-background-onhover);
    }
}
